import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  container: {
    // width: '100%',
    margin: 'auto',
    backgroundColor:'lightgrey',
    display: 'flex',
    gap:'10px',
    justifyContent:'center',
    margin:'40px auto',
    padding:'8px',
    "@media(max-width:450px)": {
      marginTop: "0em",
    },
  },
  circle: {
    height: '30px',
    width: '30px',
    borderRadius: '50%'
  }
})

const Dots = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.circle} style={{backgroundColor:'rgb(0,17,170)'}}></div>
      <div className={classes.circle} style={{backgroundColor:'rgb(254,224,0)'}}></div>
      <div className={classes.circle} style={{backgroundColor:'rgb(238,20,31)'}}></div>
      <div className={classes.circle} style={{backgroundColor:'white'}}></div>
      <div className={classes.circle} style={{backgroundColor:'orange'}}></div>

    </div>
  )
}

export default Dots
