import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  container: {
    padding: '40px',
    // border:'1.5px solid black',
    // borderRadius:'30%',
    // textAlign: 'center',
    maxWidth: '900px',
    margin: 'auto',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    '@media(max-width:500px)': {
      padding: '20px',
      marginBottom: '50px'
    }
  },
  heading: {
    fontSize: 31,
    color: 'black',
    fontWeight: 700,
    textAlign: 'center',
    '@media(max-width:500px)': {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '0px'
    }
  },
  content: {
    color: 'grey',
    fontSize: 23,
    fontWeight: 600,
    letterSpacing: '1px',
    '@media(max-width:500px)': {
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: '0px'
    }
  }
})

const MainContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <p className={classes.heading}>बौद्धमय भारत बनाओं अभियान क्या है</p>
      <p className={classes.content}>
        बौद्धमय भारत बनाओं अभियान भारत में एवं विश्व में नैतिकता, भाईचारा,
        मानवता, शांति एवं बाबा साहब डॉ भीमराव अंबेडकर के अनुसार धम्म स्थापित
        करना साथ ही बहुजन समाज के महामानव तथागत गौतम बुद्ध, चक्रवती महान सम्राट
        अशोक, संत कबीर , संत रविदास, संत गुरु नानक,संत चोखा मेला, संत तुकाराम,
        संत गुरु घासीदास, संत कवि भीमा भोई, महामानव ज्योतिबा फुले, महामानव
        सावित्रीबाई फुले, महामानव फातिमा शेख, महामानव गुरु नारायणा, महामानव
        छत्रपति साहू महाराज, महामानव पेरियार रामास्वामी नायकर, महामानव पेरियार
        ललई सिंह यादव, महामानव बिरसा मुंडा, महामावन गाडगे बाबा, महामानव
        देवनारायण, महामानव बाबा साहब डॉ भीमराव अंबेडकर, महामानव मान्यवर काशीराम
        साहब आदि बहुजन समाज में जन्मे हुए सभी संतों गुरुओं महामानवों के सामाजिक,
        धम्मिक, आर्थिक और राजनीतिक विचारों को जन-जन तक पहुंचाना साथ ही बहुजन
        समाज की सामाजिक, धार्मिक, आर्थिक और राजनीतिक शक्ति को मजबूत कर बहुजन
        समाज के महामानवों के सपनों का भारत एवं विश्व बनाना, जिससे भारत के
        साथ-साथ पूरे विश्व में नैतिकता, भाईचारा, मानवता एवं शांति स्थापित हो
        सके, तथा भारत के साथ ही साथ पूरे विश्व में भी समानता, स्वतंत्रता,
        भाईचारा एवं न्याय का राज स्थापित हो सके। यानी बाबा साहब डॉ भीमराव
        अंबेडकर जी के सपनों का भारत एवं विश्व को पुनः स्थापित करने का अभियान है।
      </p>
    </div>
  )
}

export default MainContent
