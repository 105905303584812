import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import LogoDevIcon from '@mui/icons-material/LogoDev'
import { makeStyles } from '@mui/styles'
import logo23 from '../assets/logo.png'
import { Link } from 'react-router-dom'

const pages = [
  { name: 'मुख्य पृष्ठ', path: '/' },
  { name: 'पंजीकरण', path: '/register' }
]
const settings = ['Profile', 'Account', 'Dashboard', 'Logout']

const useStyles = makeStyles({
  appBar: {
    backgroundColor: 'whitesmoke'
  }
})

function TheHeader() {
  const classes = useStyles()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar
      position='fixed'
      className={classes.appBar}
      sx={{ backgroundColor: 'white', color: 'blue', padding: '10px' }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          {/* <LogoDevIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Typography
            variant='h6'
            noWrap
            component='img'
            src={logo23}
            sx={{
              ml: '40px',
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              width: '75px',
              height: '75px'
            }}
          >
            {/* LOGO */}
          </Typography>

          {/* For mobile screen ------> */}
          <Typography
            variant='h5'
            noWrap
            component='img'
            src={logo23}
            sx={{
              margin: 'auto',
              display: { xs: 'flex', md: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              height: '60px',
              width: '60px',
              // justifyContent:'left'
            }}
          ></Typography>
           <Box sx={{ display: { xs: 'flex', md: 'none' },marginLeft:'230px' }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center'>
                    <Link to={page.path} style={{textDecoration:'none',color:'#333'}}>{page.name}</Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* mobile screen-----> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              ml: '40px'
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                href={page.path}
                // variant="outlined"
                color='primary'
                className={classes.button}
                size='large'
                sx={{
                  my: 2,
                  color: '#333',
                  display: 'block',
                  fontWeight: 600,
                  fontSize: '18px',
                  fontFamily: 'Montserrat,sans-serif',
                  textTransform: 'none',
                  mr: '10px',
                  ':hover': {
                    color: 'orange'
                    // textDecoration:'underline'
                  }
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default TheHeader
