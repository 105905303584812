import './App.css';
import RouteComponent from './components/RouteComponent';
import TheHeader from './components/TheHeader';

function App() {
  return (
    <div className="App">
      <TheHeader/>
      <RouteComponent/>
    </div>
  );
}

export default App;
