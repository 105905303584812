// import { Collapse, makeStyles } from "@material-ui/core";
import { AddCircle, RemoveCircle } from '@material-ui/icons'
import clsx from 'clsx'
import { useState } from 'react'
import globalStyle from '../assets/css/GlobalStyle'
import { makeStyles } from '@mui/styles'
import { Collapse } from '@mui/material'

const style = makeStyles({
  root: {
    textAlign: 'center',
    marginBottom: '5em',
    '@media(max-width:800px)': {
      margin: '10px'
    },
    '@media(max-width:450px)': {
      margin: '15px',
      marginTop: '40px'
    }
  },
  questionContainer: {
    marginTop: '2em'
  },
  questionBlock: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '730px',
    margin: 'auto',
    padding: '0px 0px 1em 5px',
    textAlign: 'left',
    '@media(max-width:700px)': {
      width: '100%',
      padding: '0px 5px 1em 5px'
    }
  },
  borderBottomQuestion: {
    borderBottom: '1px solid #BFD2FD',
    margin: '2em auto',
    maxWidth: '730px',
    paddingBottom: 25
  }
})

const Faq = () => {
  const global = globalStyle()
  const classes = style()

  const [First, setFirst] = useState(true)
  const add = () => {
    setFirst(!First)
  }
  const [Second, setSecond] = useState(true)
  const plus = () => {
    setSecond(!Second)
  }
  const [Third, setThird] = useState(true)
  const remove = () => {
    setThird(!Third)
  }
  const [Fourth, setFourth] = useState(true)
  const toggle = () => {
    setFourth(!Fourth)
  }
  const [Fifth, setFifth] = useState(true)
  const toggleBot = () => {
    setFifth(!Fifth)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={global.breakLine} />
        <div className={clsx(global.sectionHeading)} >सामान्य प्रश्न</div>
        <div className={classes.questionContainer}>
          <div className={classes.borderBottomQuestion}>
            <div>
              <div onClick={add} className={`${classes.questionBlock}`}>
                {First === true ? (
                  <AddCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                ) : (
                  <RemoveCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                )}
                <p className={global.semiBold} style={{ marginBottom: 0 }}>
                पद यात्रा एवं धम्म चारिका कहां से शुरू होगी और कौन - कौन से स्थान से होकर
                  गुजरेगी।{' '}
                </p>
              </div>
              <div
                className={clsx(global.sectionParagraph, global.themeBlack)}
                style={{ textAlign: 'left' }}
              >
                <Collapse in={!First}>
                  बौद्धमय भारत बनाओं अभियान के तत्वाधान में 10 दिवसीय विश्व
                  शांति संदेश पद यात्रा एवं धम्म चारिका दिनांक 14 अक्टूबर 2023
                  काशीपुर जिला - उधमसिंह नगर उत्तराखंड से शुरू होकर 24 अक्टूबर
                  2023 को 26 अलीपुर रोड दिल्ली विधानसभा सिविल लाइन बाबा साहब डॉ
                  भीमराव अंबेडकर जी का निवास स्थान (बाबा साहब का अंतरराष्ट्रीय
                  मेमोरियल) पर संपन्न होगी । यह यात्रा सम्राट अशोक कालीन प्राचीन
                  बुध्द स्थल गोविषाण टीला (चैत्य मैदान) काशीपुर उधमसिंह नगर
                  उत्तराखंड से शुरू होकर अलीगंज, पीपलसाना, मुरादाबाद, जौया,
                  गजरौला, गढ़मुक्तेश्वर, हापुड, गाजियाबाद, मीत नगर दिल्ली से 26
                  अलीपुर रोड दिल्ली पहुंचेगी।
                </Collapse>
              </div>
            </div>
          </div>
          <div className={classes.borderBottomQuestion}>
            <div>
              <div onClick={plus} className={`${classes.questionBlock}`}>
                {Second === true ? (
                  <AddCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                ) : (
                  <RemoveCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                )}
                <p className={global.semiBold} style={{ marginBottom: 0 }}>
                पद यात्रा एवं धम्म चारिका में शामिल होने वाले उपासकों एवं उपासिकाओं के नाम
                  की सूची
                </p>
              </div>
            </div>
            <div
              className={clsx(global.sectionParagraph, global.themeBlack)}
              style={{ textAlign: 'left' }}
            >
              <Collapse in={!Second}>
              पद यात्रा एवं धम्म चारिका में शामिल होने वाले धम्म उपासकों, एवं उपासिकाओं के
                नाम, शिक्षा, व्यवसाय, उम्र फ़ोटो, पता एवं कॉन्टेक्ट नम्बर
              </Collapse>
            </div>
          </div>
          {/* <div className={classes.borderBottomQuestion}>
            <div>
              <div onClick={remove} className={`${classes.questionBlock}`}>
                {Third === true ? (
                  <AddCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                ) : (
                  <RemoveCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                )}
                <p className={global.semiBold} style={{ marginBottom: 0 }}>
                  धम्म चारिका में आप कैसे शामिल हो सकते है ?
                </p>
              </div>
            </div>
            <div
              className={clsx(global.sectionParagraph, global.themeBlack)}
              style={{ textAlign: 'left' }}
            >
              <Collapse in={!Third}>
                धम्म चारिका में रजिस्ट्रेशन करवा कर, ट्रैन से, बस से 13 अक्टूबर
                2023 को काशीपुर उत्तराखण्ड आकर आप धम्म चारिका में शामिल हो सकते
                है। धम्म चारिका में शामिल होने वाले उपासकों एवं उपासिकाओं को
                अपने साथ दो जोड़ी सफेद ड्रेस (धम्म उपासकों के लिए हाफ बाजू की
                शर्ट एवं पेंट तथा धम्म उपासिकाओं के लिए सफेद शूट) एक कपड़े का
                साबुन, एक नहाने का साबुन, सेविंग किट, एक बेड शीट, एक मच्छरदानी,
                एक ओढ़ने की चादर साथ में लाना अनिवार्य है।
              </Collapse>
            </div>
          </div> */}
          <div className={classes.borderBottomQuestion}>
            <div>
              <div onClick={toggle} className={`${classes.questionBlock}`}>
                {Fourth === true ? (
                  <AddCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                ) : (
                  <RemoveCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                )}
                <p className={global.semiBold} style={{ marginBottom: 0 }}>
                पद यात्रा एवं धम्म चारिका में आपकी भागीदारी से बहुजन समाज को कैसे मदद मिलेगी
                  ?
                </p>
              </div>
            </div>
            <div
              className={clsx(global.sectionParagraph, global.themeBlack)}
              style={{ textAlign: 'left' }}
            >
              <Collapse in={!Fourth}>
              पद यात्रा एवं धम्म चारिका में आपकी भागीदारी से बहुजन समाज में नैतिकता, समानता,
                स्वतंत्रता, भाईचारा एवं न्याय तथा विश्व में शांति स्थापित होगी ।
                जिससे भारत देश के साथ-साथ पूरे विश्व में मानवतावादी व्यवस्था का
                माहौल बनेगा। जब पूरे विश्व में मानवतावादी व्यवस्था का माहौल
                बनेगा, तो पूरे विश्व के लोग अमानवीय व्यवस्था को खत्म कर
                मानवतावादी व्यवस्था को स्थापित करने के लिए बाध्य होंगे।
              </Collapse>
            </div>
          </div>
          <div className={classes.borderBottomQuestion}>
            <div>
              <div onClick={toggleBot} className={`${classes.questionBlock}`}>
                {Fifth === true ? (
                  <AddCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                ) : (
                  <RemoveCircle
                    htmlColor='#2C63E5'
                    fontSize='small'
                    style={{ marginRight: 16 }}
                  />
                )}
                <p className={global.semiBold} style={{ marginBottom: 0 }}>
                  धम्म चारिका क्या है ?{' '}
                </p>
              </div>
            </div>
            <div
              className={clsx(global.sectionParagraph, global.themeBlack)}
              style={{ textAlign: 'left' }}
            >
              <Collapse in={!Fifth}>
                आज से लगभग 2500 साल पहले तथागत गौतम बुद्ध ने धम्म चारिका की खोज
                की थी। धम्म चारिका का मतलब है एक लाइन में पैदल चलकर एक स्थान से
                दूसरे स्थान पर भिक्खाटन करते हुवे एवं धम्म देशना देते हुवे पैदल
                - पैदल जाना। जो भी भिक्खु एवं श्रेष्ठ उपासक, उपासिका चारिका में
                शामिल होते है। उनको सामान्य उपासक एवं उपासिका भोजन दान देते है।
                भिक्खाटन करने के बाद उस स्थान के धम्म उपासकों एवं धम्म उपासिकाओं
                को धम्म देशना भी दी जाती है। धम्म देशना का मतलब है, तथागत गौतम
                बुद्ध के उपदेशों को बताना। तथागत गौतम बुद्ध ने चारिका के विषय
                में बताया था- बहुजन हिताय, बहुजन सुखाय - चरथ भिक्खवे चारिकं,
                बहुजन हिताय बहुजन सुखाय, लोकानुकम्पाय अत्थाय हिताय
                देवमनुस्सानं,देसेथ भिक्खवे धम्मं आदिकल्याणं, मज्झ
                कल्याणं,परियोसोत कल्याणं, सात्थ सब्बं जनं ब्रह्मचरियं पकासेथ ||
                अर्थात- भिक्खुओं, बहुजनों के हित के लिए, बहुजनों के सुख के लिए,
                विश्व के सभी प्राणियों पर अनुकम्पा करने के लिए, मानवता के कल्याण
                के लिए,चारिका कर धम्मोपदेश शुरू करो | यह धम्म प्रारंभ में, मध्य
                में, एवं अन्तमें सभी जगह कल्याणकारी हैं, ऐसे धम्म के मार्ग का
                लोगों में उपदेश करो | यानी धम्म चारिका में बहुजनों का हित,
                बहुजनों का शुख एवं बहुजनों का कल्याण समाहित है। विश्व शांति
                सन्देश पद यात्रा एवं धम्म चारिका में भारत देश की बहुजन समाज के
                सभी सामाजिक, धम्मिक एवं आर्थिक संगठन शामिल है। बहुजन समाज के सभी
                संगठनों के सहयोग से यह धम्म चारिका सफल होगी।
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq
