import React from 'react'
import { makeStyles } from '@mui/styles';
import slide_1 from "../assets/slider/image_1.jpeg"
import slide_2 from "../assets/slider/image_2.jpeg"
import slide_3 from "../assets/slider/image_3.jpg"
import slide_4 from "../assets/slider/image_4.jpg"
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const useStyles = makeStyles({
    root: {
    //   paddingTop: "4em",
      paddingBottom: "6em",
      "@media(max-width:1054px)": {
        paddingTop: "1em",
        paddingBottom: "0em",
      },
    },
    content: {
      maxWidth: "100%",
      height:'680px',
    //   border:'1px solid red',
      margin: " 0px 1em  !important",
      backgroundColor: "#fff",
      borderRadius: "10px",
      "@media(max-width:750px)": {
        margin: "0px  !important",
      },
      "@media(max-width:450px)": {
        height:'300px'
      },
    },
    imgBlock: {
      width: "100% !important",
      margin: "auto !important",
      // height: "257px !important",
    },
    img: {
      width: "100% !important",
      
      // height: "100% !important",
    },
    carouselContainer: {
      width: "100%",
      maxWidth: "1240px !important",
      margin: "auto",
      "& .owl-nav": {
        position: "absolute",
        bottom: "0%",
        right: -50,
        left: -50,
        display: "flex",
        justifyContent: "space-between",
        padding: "16px 0px",
        margin: "auto",
        top: "36%",
        "@media(max-width:1095px)": {
          right: -41,
          left: -41,
        },
        "@media(max-width:950px)": {
          right: -34,
          left: -34,
        },
        "@media(max-width:750px)": {
          top: "100%",
          marginTop: "9px !important",
          left: 0,
          right: 0,
        },
        "@media(max-width:450px)": {
            paddding:'0px',
            top: "18%",
            marginTop: "9px !important",
            // left: 5,
            // right: -5,
          },
      },
      "& .owl-nav button": {
        fontSize: "45px !important",
        color: "black !important",
        opacity:0.9,
        width: 45,
        height: 45,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .owl-nav button span": {
        height: "45px",
        width: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // border: "1px solid #2C63E5  !important",
        background:'whitesmoke',
        borderRadius: "50%",
        paddingBottom: "4px !important",
      },
    },
    dotNavigation: {
      display: "flex",
      margin: " 5px 7px",
      opacity: 0.5,
      borderRadius: "20px",
      border: " none",
      alignItems: " center",
      justifyContent: "center",
      position: "absolute",
      bottom: "-60px",
      left: 0,
      right: 0,
  
      "& .owl-dot": {
        width: "14px",
        height: "14px",
        background: "#D7E3FE !important",
        marginRight: "14px",
        borderRadius: "50px",
        "@media(max-width:500px)": {
          width: "8px",
          height: "8px",
          marginRight: "8px",
        },
      },
      "& .active": {
        background: "#BFD2FD !important",
      },
      "@media(max-width:650px)": {
        display: "flex",
      },
    },
  });

  const data = [
    {
      img: slide_1,
    },
    {
      img: slide_2,
    },
    {
      img: slide_3,
    },
    {
      img: slide_4,
    },

  ];

  const responsiveCarousel = {
    responsive: {
      0: {
        items: 1,
      },
      650: {
        items: 1,
      },
      790: {
        items: 2,
      },
      920: {
        items: 3,
      },
      1000: {
        items: 3,
      },
      1101: {
        items: 1,
      },
    },
  };

const ImageSlider = () => {
    const classes=useStyles();
    
    return (
        <>
          <div className={classes.root}>
            <ReactOwlCarousel
              className={classes.carouselContainer}
              loop={true}
              autoplayHoverPause={true}
              navSpeed={1000}
              autoplay={false}
            //   autoplaySpeed={3000}
            //   autoplayTimeout={5000}
              {...responsiveCarousel}
              nav={true}
            //   dots={true}
            //   dotsClass={classes.dotNavigation}
            >
              {data.map((item, index) => {
                return (
                  <div className={classes.content} key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <div>
                        <div className={classes.imgBlock}>
                          <img
                            className={classes.img}
                            src={item.img}
                            alt="Products"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ReactOwlCarousel>
          </div>
        </>
      );
}

export default ImageSlider
