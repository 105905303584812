import { makeStyles } from '@mui/styles'

const globalStyle = makeStyles({
  themeBlack: {
    color: '#1A1A1A !important'
  },
  sectionParagraph: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    color: '#666'
  },
  semiBold: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "150%",
    color: "#1a1a1a",
    marginBottom: 10,
  },
  sectionHeading: {
    fontSize: 36,
    fontWeight: 700,
    margin: 0,
    textAlign: "center",
    "@media(max-width:960px)": {
      fontSize: 24,
    },
    "@media(max-width:400px)": {
      fontSize: 22,
    },
    "@media(max-width : 350px)": {
      fontSize: "20px",
    },
  },
})

export default globalStyle