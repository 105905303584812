import { Button } from '@mui/material'
import React from 'react'

const RegisterNow = () => {
  return (
    <Button variant='contained' size='large' href='/register' sx={{fontSize:20,fontWeight:600}}>
      अभी पंजीकरण करें
    </Button>
  )
}

export default RegisterNow
