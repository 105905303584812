import React, { useEffect, useState } from 'react'
import hero from '../assets/Flag_of_Buddhism.svg.png'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'
import axios from 'axios'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import InputFieldbox from '../components/InputFieldBox'
import Dots from '../components/Dots'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';


const useStyles = makeStyles({
  container: {
    // backgroundColor:'cornsilk',
    maxWidth: '1400px',
    margin: 'auto',
    marginTop: '120px',
    // padding: '50px',
    '@media(max-width : 450px)': {
      // padding: '20px',
      marginTop: '105px'
    }
  },
  header: {
    fontWeight: 700,
    fontSize: 40,
    fontFamily: 'system-ui',
    textAlign: 'center',
    color: 'red',
    marginLeft: '10px',
    '@media(max-width : 450px)': {
      fontWeight: 500,
      fontSize: 23
    }
  },
  mainHeader: {
    fontWeight: 800,
    fontSize: 48,
    fontFamily: 'system-ui',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'rgb(42,169,224)',
    padding: '13px',
    '@media(max-width : 450px)': {
      fontWeight: 500,
      fontSize: 28
    }
  },
  count: {
    fontWeight: 600,
    fontSize: 21,
    lineHeight: '47px',
    // textAlign: 'left',
    fontFamily: "'Source Sans Pro', sans-serif",
    textAlign: 'center',
    '@media(max-width : 450px)': {
      fontWeight: 500,
      lineHeight: '27px'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 24,
    '@media(max-width : 600px)': {
      flexDirection: 'column',
      paddingBottom: 0
    }
  },
  col: {
    width: '48%',
    '@media(max-width : 600px)': {
      width: '100%',
      paddingBottom: 24
    }
  },
  inputRow: {
    paddingBottom: 24
  },
  submitBtn: {
    backgroundColor: 'brown',
    width: '100%',
    border: '0px',
    color: '#fff',
    borderRadius: '4px',
    padding: '10px 24px',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    cursor: 'pointer'
  },
  formikBox: {
    maxWidth: '70%',
    marginTop: '40px',
    margin: 'auto',
    marginBottom: '50px',
    '@media(max-width : 450px)': {
      maxWidth: '94%'
    }
  }
})

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, 'Invalid input')
    .required('Invalid input'),

  phone: Yup.string().min(10, 'Invalid input').required('Invalid input'),
  pin: Yup.string().min(6, 'Invalid input').required('Invalid input'),
  district: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, 'Invalid input')
    .required('Invalid input'),
  state: Yup.string()
    .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, 'Invalid input')
    .required('Invalid input'),
  local_address: Yup.string().required('Invalid input')
})

const Register = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const [count, setCount] = useState()
  const [loading, setLoading] = useState(false)
  const initialValues = {
    name: '',
    phone: '',
    email: '',
    pin: '',
    district: '',
    state: '',
    local_address: ''
  }

  const handleSubmitForm = async (props) => {
    const { loading, resetForm, values } = props
    console.log(values)
    const event = 'Dhamma Shanti Sandesh Padh Yatra'
    const { name, phone, email, pin, state, local_address, district } = values
    const payload = {
      address: { pin, state, local_address, district },
      name,
      phone,
      email,
      event
    }

    loading(true)
    console.log(payload, 'payload')
    try {
      const res = axios.post(
        'https://samyak-backend.onrender.com/api/event?key=samayk@3007',
        payload
      )

      resetForm()
      setOpen(true)

      // const data = res.data
      // if (data._id) {
      //   resetForm()
      // }
    } catch (error) {
      console.log('api', error.message)
    } finally {
      loading(false)
    }
  }

  const getCount = () => {
    axios
      .get('https://samyak-backend.onrender.com/api/eventRegisteration/count')
      .then((r) => setCount(r.data.count))
      .catch((err) => console.log(err))
  }

  if (initialValues.pin.length === 6) {
    console.log(initialValues.pin)
  }

  useEffect(() => {
    getCount()
  }, [])

  return (
    <>
      <div>
        <div className={classes.container}>
          <p className={classes.mainHeader} style={{ textAlign: 'center' }}>
            बौद्धमय भारत बनाओं अभियान <br />
            के तत्वावधान में <br /> दस दिवसीय विश्व शांति सन्देश पद यात्रा एवं
            धम्म चारिका
          </p>
          <p className={classes.header}>अभी पंजीकरण करें</p>
          {/* <img src={hero} alt='हीरो' width={'100%'} height='300px' /> */}
          {/* <Dots/> */}
          <p className={classes.count}>
            प्रतिभागियों की बढ़ती हुई लहर में शामिल हों!
            <br />{' '}
            {`इस अभियान मे ${count ? count : '101'} प्रतिभागी जुड़ चुके है। `}
          </p>
          <Box className={classes.formikBox}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                console.log(values, 'ans')
                handleSubmitForm({
                  loading: setLoading,
                  resetForm: resetForm,
                  values: values
                })
              }}
            >
              {(formikProps) => {
                return (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <div>
                      <div className={classes.row}>
                        <div className={classes.col}>
                          <InputFieldbox
                            labelText='नाम'
                            placeholder='अपना नाम दर्ज करें'
                            name='name'
                            formikProps={formikProps}
                            values={formikProps.values.name}
                          />
                        </div>
                        <div className={classes.col}>
                          <InputFieldbox
                            labelText='फ़ोन नंबर'
                            placeholder='अपना फ़ोन नंबर दर्ज करें'
                            name='phone'
                            formikProps={formikProps}
                            values={formikProps.values.phone}
                          />
                        </div>
                      </div>

                      <div className={classes.row}>
                        <div className={classes.col}>
                          <InputFieldbox
                            labelText='ईमेल'
                            placeholder='अपना ईमेल दर्ज करें'
                            name='email'
                            formikProps={formikProps}
                            values={formikProps.values.email}
                            required={false}
                          />
                        </div>
                        <div className={classes.col}>
                          <InputFieldbox
                            labelText='पिनकोड'
                            placeholder='अपना पिनकोड दर्ज करें'
                            name='pin'
                            formikProps={formikProps}
                            values={formikProps.values.pin}
                          />
                        </div>
                      </div>
                      <div className={classes.inputRow}>
                        <InputFieldbox
                          labelText='पता'
                          placeholder='अपना पता दर्ज करें'
                          name='local_address'
                          formikProps={formikProps}
                          values={formikProps.values.local_address}
                        />
                      </div>
                      <div className={classes.row}>
                        <div className={classes.col}>
                          <InputFieldbox
                            labelText='शहर'
                            placeholder='अपना शहर दर्ज करें'
                            name='district'
                            formikProps={formikProps}
                            values={formikProps.values.district}
                          />
                        </div>
                        <div className={classes.col}>
                          <InputFieldbox
                            labelText='राज्य'
                            placeholder='अपना राज्य दर्ज करें'
                            name='state'
                            formikProps={formikProps}
                            values={formikProps.values.state}
                          />
                        </div>
                      </div>

                      <div style={{ width: '50%', margin: 'auto' }}>
                        <button
                          className={classes.submitBtn}
                          type='submit'
                          disabled={loading}
                        >
                          {loading ? 'सबमिट हो रहा है...' : 'पंजीकृत करे'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </div>
        <div>
          {/* <Button variant='outlined' onClick={handleClick}>
            Open success snackbar
          </Button> */}
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: '100%' }}
            >
              पंजीकरण सफलतापूर्वक हो गया!
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  )
}

export default Register
