// import { Container, Grid } from "@material-ui/core";
import { Container, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
// import globalStyle from "../../../assets/css/GlobalStyle";
// import img from "../../../assets/images/ServicePtlTracking/rafiki.png";

const useStyle = makeStyles({
  root: {
    background: '#151E33',
    padding: '4em 3em',
    marginTop: '6em',
    // height: "auto",
    '@media(max-width:599px)': {
      // height: "auto",
      padding: '2em 0em',
      textAlign: 'center'
    }
  },
  paragraph: {
    fontSize: 16,
    fontWeight: 300,
    color: '#BFD2FD',
    '@media(max-width:760px)': {
      fontSize: 14
    }
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    '@media(max-width:599px)': {
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  line: {
    // justifyContent:"center",
    marginTop: '16px',
    height: '1px',
    width: '158px',
    backgroundColor: '#BFD2FD',
    '@media(max-width:599px)': {
      // display: "none",
      margin: 'auto',
      marginTop: 16,
      marginBottom: '40px'
    }
  },
  num: {
    fontSize: '22px',
    color: '#FFFFFF',
    fontWeight: 600,
    '@media(max-width:599px)': {
      fontWeight: 500
    }
  }
})

const Slate = () => {
  const classes = useStyle()
  //   const global = globalStyle();

  return (
    <>
      {/* <Container maxWidth='none'> */}
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item sm={6} md={6} xs={12}>
            <p className={classes.paragraph}>दिनांक:</p>
            <div className={classes.line}></div>
            <p className={classes.num}>14 अक्टूबर 2023 से 24 अक्टूबर 2023 तक</p>
          </Grid>
          <Grid item sm={6} md={6} xs={12}>
            <p className={classes.paragraph}>स्थान:</p>
            <div className={classes.line}></div>
            <p className={classes.num}>
              गोविषाण टीला (चैत्य मैदान) से शुरू होकर बाबा साहब डॉ भीमराव
              अंबेडकर जी के निवास स्थान सिविल लाइन 26 अलीपुर रोड दिल्ली पर
              सम्पन्न होगी।
            </p>
            {/* <div className={classes.line}></div> */}
          </Grid>
          {/* <Grid item sm={6} md={4} xs={12}>
              <p className={classes.num}>1M+</p>
              <p className={classes.paragraph}>Consignments Tracked</p>
              <div className={classes.line}></div>
            </Grid> */}
        </Grid>
      </div>
      {/* </Container> */}
    </>
  )
}

export default Slate
