import React from 'react'
import ImageSlider from '../components/ImageSlider'
import { makeStyles } from '@mui/styles'
import Register from './Register'
import Dots from '../components/Dots'
import Slate from '../components/Slate'
import MainContent from '../components/MainContent'
import RegisterNow from '../components/RegisterNow'
import { Box } from '@mui/material'
import Faq from '../components/Faq'

const useStyles = makeStyles({
  container: {
    // backgroundColor:'cornsilk',
    maxWidth: '1400px',
    margin: 'auto',
    marginTop: '0px',
    padding: '50px',
    justifyContent: 'center',
    '@media(max-width : 450px)': {
      padding: '20px',
      marginTop: '30px'
    }
  },
  containerXsa: {
    maxWidth: '1400px',
    margin: 'auto',
    marginTop: '0px',
    // padding: '50px',
    justifyContent: 'center',
    '@media(max-width : 450px)': {
      padding: '20px',
      // marginTop: '30px'
    }
  },
  mainHeader: {
    fontWeight: 800,
    fontSize: 48,
    fontFamily: 'system-ui',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'rgb(42,169,224)',
    padding: '13px',
    '@media(max-width : 450px)': {
      fontWeight: 500,
      fontSize: 28
    }
  }
})

const Home = () => {
  const classes = useStyles()
  return (
    <>
      <div maxWidth='none' style={{ margin: 'auto', marginTop: '100px' }}>
        <ImageSlider />
        <p className={classes.mainHeader} style={{ textAlign: 'center' }}>
            बौद्धमय भारत बनाओं अभियान <br />
            के तत्वावधान में <br /> दस दिवसीय विश्व शांति सन्देश पद यात्रा एवं
            धम्म चारिका
          </p>
        <Slate />
      </div>
      <div className={classes.container}>
        <Box textAlign={'center'} marginBottom={'40px'}>
          <RegisterNow />
        </Box>
        <MainContent />
      </div>
      <Dots/>
      <div className={classes.containerXsa}>
        <Faq/>
      </div>
      <Dots/>
    </>
  )
}

export default Home
