import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import React from "react";
import {  ErrorMessage } from "formik";
import { Warning } from "@material-ui/icons";


const Styles = makeStyles({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      // height: "30px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 10px !important",
      height: "20px",
      // marginBottom:"-10px"
    },
    "& .MuiSelect-select": {
      border: "1px solid #C7C7C7",
      borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: "#1A1A1A",
      fontFamily: "'Source Sans Pro', sans-serif",
      opacity: 1,
      lineHeight: "20px",
      "&::placeholder": {
        color: "#666666",
        fontFamily: "'Source Sans Pro', sans-serif",
        opacity: 1,
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #2C63E5",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #2C63E5",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.26) !important",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: "0px !important",
    },
  },
  labelText: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 500,
    marginRight: 3,
    paddingBottom: 8,
    display: "inline-block",
  },
  star: {
    color: "red",
    display: "inline-block",
    marginLeft: "3px",
  },
});

const InputFieldbox = (props) => {
  const {
    labelText,
    placeholder,
    formikProps,
    name,
    isMultiline = false,
    required = true,
  } = props;
  const classes = Styles();
  return (
    <div className={classes.root}>
      <label className={classes.labelText}>
        {labelText}
        {required && <span className={classes.star}>*</span>}
      </label>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={placeholder}
        name={name}
        multiline={isMultiline}
        minRows={4}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange(name)}
        error={
          formikProps.touched[`${name}`] &&
          Boolean(formikProps.errors[`${name}`])
        }
      />
      {formikProps.touched[`${name}`] && formikProps.errors[`${name}`] && (
        <div
          style={{
            color: "red",
            fontSize: 12,
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            lineHeight: "16px",
          }}
        >
          <>
            <Warning
              style={{
                fontSize: 12,
                verticalAlign: "middle",
                marginRight: "5px",
              }}
            />
            <ErrorMessage name={name} />
          </>
        </div>
      )}
    </div>
  );
};

export default InputFieldbox;
